<template>
  <modal
    name="theme-modal"
    class="theme-modal"
    :focus-trap="true"
    :draggable="!isMobileTablet"
    @before-close="beforeClose"
  >
    <div class="theme-modal-container">
      <div class="modal-title border-bottom text-secondary position-relative">
        <p class="p-2 m-0">System Theme Colors</p>
        <button class="close mr-3 pr-2" @click="closeModal">&times;</button>
      </div>
      <div class="content-container">
        <div class="d-flex flex-wrap justify-content-center py-5">
          <div
            v-for="item in availableColors"
            :key="item.id"
            class="
              mr-5
              colors-item
              d-flex
              align-items-center
              justify-content-center
              px-3
              py-2
            "
            :class="item.id == selectedTheme.id ? 'selected' : ''"
            @click="updateSelectedTheme(item)"
          >
            <div class="border border-dark" style="height: 32px">
              <span
                class="color-container"
                :style="`background-color: ${item.themeColor}`"
              ></span>
              <span
                class="color-container"
                :style="`background-color: ${item.bodyColor}`"
              ></span>
              <span
                class="color-container"
                :style="`background-color: ${item.secondaryColor}`"
              ></span>
              <span
                class="color-container"
                :style="`background-color: ${item.textColor}`"
              ></span>
            </div>
          </div>
        </div>
        <div class="p-4 d-flex justify-content-between border-top border-top-2">
          <button class="btn btn-light col-5" @click="closeModal">
            Cancel
          </button>
          <button class="btn btn-theme col-5" @click="storeSelectedTheme">
            Apply
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ThemeModal",
  data() {
    return {
      selectedTheme: localStorage.getItem("THEME")
        ? JSON.parse(localStorage.getItem("THEME"))
        : { id: 1 },
      availableColors: [
        {
          id: 1,
          themeColor: "#7ce2fe",
          secondaryColor: "#cbcfcd",
          textColor: "#000000",
          bodyColor: "#dfe2df",
          btnColor: "#000000",
          toolsImg: "/images/tools-blue.svg",
          notesImg: "/images/notes-blue.svg",
          profileImg: "/images/profile-blue.svg",
        },
        {
          id: 2,
          themeColor: "#ffc53d",
          secondaryColor: "#ebdaca",
          textColor: "#000000",
          bodyColor: "#f0e4d9",
          btnColor: "#000000",
          toolsImg: "/images/tools-orange.svg",
          notesImg: "/images/notes-orange.svg",
          profileImg: "/images/profile-orange.svg",
        },
        {
          id: 3,
          themeColor: "#bdee63",
          secondaryColor: "rgba(0,0,0,0.2)",
          textColor: "#000000",
          bodyColor: "#dfe2e0",
          btnColor: "#000000",
          toolsImg: "/images/tools-green.svg",
          notesImg: "/images/notes-green.svg",
          profileImg: "/images/profile-green.svg",
        },
        {
          id: 4,
          themeColor: "yellow",
          secondaryColor: "#f0f0d9",
          textColor: "#000000",
          bodyColor: "#e6e6e6",
          btnColor: "#000000",
          toolsImg: "/images/tools-black.svg",
          notesImg: "/images/notes-black.svg",
          profileImg: "/images/profile-black.svg",
        },
        {
          id: 5,
          themeColor: "#f014b2",
          secondaryColor: "#ffabde",
          textColor: "#333333",
          bodyColor: "#e8c4da",
          btnColor: "#ffffff",
          toolsImg: "/images/tools-black.svg",
          notesImg: "/images/notes-black.svg",
          profileImg: "/images/profile-black.svg",
        },
        // {
        //   // Ultramarine Blue
        //   id: 6,
        //   themeColor: "#6283fe",
        //   secondaryColor: "#839dfb",
        //   textColor: "#000000",
        //   bodyColor: "#9eb3fe",
        //   toolsImg: "/images/tools-blue.svg",
        // },
        // {
        //   id: 7,
        //   themeColor: "#000000",
        //   secondaryColor: "#333333",
        //   textColor: "#a66b01",
        //   bodyColor: "#333333",
        //   toolsImg: "/images/tools-orange.svg",
        // },
      ],
    };
  },
  computed: {
    ...mapState({
      deviceType: (state) => state.settings.deviceType,
    }),
    isMobileTablet: function () {
      return this.deviceType == "mobile" || this.deviceType == "tablet";
    },
  },
  methods: {
    closeModal: function () {
      this.$modal.hide("theme-modal");
    },
    beforeClose: function () {
      const storedTheme = localStorage.getItem("THEME")
        ? JSON.parse(localStorage.getItem("THEME"))
        : this.availableColors[0];
      this.updateSelectedTheme(storedTheme);
    },
    updateSelectedTheme: function (item) {
      this.selectedTheme = item;
      this.updateSystemUI(item);
    },
    storeSelectedTheme: function () {
      localStorage.setItem("THEME", JSON.stringify(this.selectedTheme));
      this.$modal.hide("theme-modal");
    },
    updateSystemUI: function (item) {
      document.documentElement.style.setProperty(
        "--theme-color",
        item.themeColor
      );
      document.documentElement.style.setProperty(
        "--secondary-color",
        item.secondaryColor
      );
      document.documentElement.style.setProperty(
        "--body-bg-color",
        item.bodyColor
      );
      document.documentElement.style.setProperty(
        "--main-text-color",
        item.textColor
      );
      document.documentElement.style.setProperty(
        "--btn-text-color",
        item.btnColor
      );
      document.documentElement.style.setProperty(
        "--tools-img-path",
        `url('${item.toolsImg}')`
      );
      document.documentElement.style.setProperty(
        "--notes-img-path",
        `url('${item.notesImg}')`
      );
      document.documentElement.style.setProperty(
        "--profile-img-path",
        `url('${item.profileImg}')`
      );
    },
  },
};
</script>

<style lang="scss">
.theme-modal {
  cursor: move;
  .v--modal-box {
    height: auto !important;
  }
}

.theme-modal-container {
  height: 100%;
  .content-container {
    overflow-y: auto;
    overflow-x: auto;
    height: calc(100% - 34px);
    position: relative;
  }

  .modal-title {
    text-align: center;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .close {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      font-size: 35px;
    }
  }

  .colors-item {
    border-radius: 8px;
    margin-bottom: 12px;
    &.selected {
      box-shadow: inset 0px 0px 6px #862f2f;
    }
  }

  .color-container {
    cursor: pointer;
    display: inline-block;
    min-width: 20px;
    min-height: 30px;
    width: 20px;
    height: 30px;
  }
}
</style>
