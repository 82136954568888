<template>
  <div>
    <div :class="`tray ${wrapClass}`" ref="tray">
      <div
        v-if="title"
        class="d-flex justify-content-between align-items-center"
        :class="`${wrapClass ? wrapClass + '-close' : ''}`"
      >
        <h2 class="ml-3 mb-0">{{ title }}</h2>
        <button
          class="close mr-3 text-dark"
          style="font-size: 40px"
          @click="close"
        >
          &times;
        </button>
      </div>
      <button
        v-else
        :class="`close mt-4 mr-4 text-dark ${
          wrapClass ? wrapClass + '-close' : ''
        }`"
        style="font-size: 40px"
        @click="close"
      >
        &times;
      </button>
      <div :class="`tray-body ${wrapClass ? wrapClass + '-body' : ''}`">
        <slot></slot>
      </div>
    </div>
    <div
      :class="`tray-bg ${wrapClass ? wrapClass + '-body' : ''}`"
      ref="tray-bg"
    >
      &nbsp;
    </div>
  </div>
</template>

<script>
export default {
  props: {
    toggle: {
      type: Boolean,
      default: false,
    },
    wrapClass: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    toggle: function (value) {
      if (value) {
        this.$refs["tray"].classList.add("open");
        this.$refs["tray-bg"].classList.add("open");
      } else {
        this.$refs["tray"].classList.remove("open");
        this.$refs["tray-bg"].classList.remove("open");
      }
    },
  },
  mounted() {
    let vm = this;
    vm.$refs["tray"].addEventListener("transitionstart", function (e) {
      if (e.target.classList.contains("open")) {
        vm.$refs["tray-bg"].style.zIndex = 1995;
      }
    });
    vm.$refs["tray"].addEventListener("transitionend", function (e) {
      if (!e.target.classList.contains("open") && !vm.toggle) {
        vm.$refs["tray-bg"].style.zIndex = -1;
      }
    });
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.tray.open {
  right: 0px !important;
  max-width: 100%;
}
.tray {
  background-color: rgba(245, 245, 245, 1);
  height: 100%;
  width: 600px;
  overflow-x: hidden;
  position: fixed;
  top: 0px;
  right: -630px;
  z-index: 2000;
  transition: right 0.3s ease-in-out;
  &.open {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }

  .tray-body {
    padding: 20px;
  }
}

.tray-bg {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: opacity 0.3s ease-in-out;
}

.tray-bg.open {
  opacity: 1;
}
</style>
