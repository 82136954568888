<template>
  <li class="nav-item">
    <router-link
      to="/tasks"
      class="nav-link u-mr-8"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      >Alerts
      <span
        v-show="showBadge"
        class="badge badge-pill badge-theme ml-1 text-black"
      >
        {{ numberOfUnreadTasks ? numberOfUnreadTasks : "0" }}
      </span>
    </router-link>
  </li>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      numberOfUnreadTasks: (state) => state.task.numberOfUnreadTasks,
    }),
    showBadge: function () {
      return this.numberOfUnreadTasks;
    },
  },
  mounted() {
    this.getMyUnreadTasksCount();
    setInterval(() => {
      this.getMyUnreadTasksCount();
    }, 30000);
  },
  methods: {
    ...mapActions({
      getMyUnreadTasksCount: "task/getMyUnreadTasksCount",
    }),
  },
};
</script>
<style lang="scss" scoped>
.badge-theme {
  background-color: var(--theme-color);
  color: var(--btn-text-color);
  font-size: 12px;
}
</style>
