<template>
  <li
    class="nav-item nav-create-task-item ml-2 position-relative"
    :class="showBadge ? 'badged w-auto px-2' : ''"
  >
    <div
      class="nav-item theme-color-item pointer-cursor"
      @click="showNotifications"
    >
      <div class="nav-item create-task-item position-relative">
        <img
          src="@/assets/img/icons/notification.png"
          class="notification-img"
          title="Notifications"
        />
        <span
          v-show="showBadge"
          class="badge badge-pill badge-theme ml-1"
          :class="countInfo.loading ? 'p-1' : ''"
        >
          <template v-if="!countInfo.loading">
            {{
              countInfo.unread > 999 ? "+99" : parseInt(countInfo.unread || 0)
            }}
          </template>
          <i v-if="countInfo.loading" class="fas fa-spinner fa-spin"></i>
        </span>
      </div>
    </div>
    <div
      class="notifications-list-container"
      ref="notificationsListContainer"
      v-if="displayNotifications"
    >
      <div class="mx-3 mt-3 text-left">
        <h2>Notifications</h2>

        <div class="border-bottom mb-2">
          <button
            @click="changeStatus('')"
            class="btn px-4 radius-0 no-shadow-btn"
            :class="status == '' ? 'active-btn text-primary' : ''"
          >
            All
            <span
              class="badge badge-pill ml-1"
              :class="`${countInfo.loading ? 'p-1' : ''}${
                status == '' ? 'badge-primary' : 'badge-secondary'
              }`"
            >
              <template v-if="!countInfo.loading">
                {{ countInfo.total }}
              </template>
              <i v-if="countInfo.loading" class="fas fa-spinner fa-spin"></i>
            </span>
          </button>
          <button
            @click="changeStatus('unread')"
            class="btn px-4 radius-0 no-shadow-btn"
            :class="status == 'unread' ? 'active-btn text-primary' : ''"
          >
            Unread
            <span
              class="badge badge-pill ml-1"
              :class="`${countInfo.loading ? 'p-1' : ''}${
                status == 'unread' ? 'badge-primary' : 'badge-secondary'
              }`"
            >
              <template v-if="!countInfo.loading">
                {{ countInfo.unread }}
              </template>
              <i v-if="countInfo.loading" class="fas fa-spinner fa-spin"></i>
            </span>
          </button>
          <button
            @click="changeStatus('read')"
            class="btn px-4 radius-0 no-shadow-btn"
            :class="status == 'read' ? 'active-btn text-primary' : ''"
          >
            Read
            <span
              class="badge badge-pill ml-1"
              :class="`${countInfo.loading ? 'p-1' : ''}${
                status == 'read' ? 'badge-primary' : 'badge-secondary'
              }`"
            >
              <template v-if="!countInfo.loading">
                {{ countInfo.read }}
              </template>
              <i v-if="countInfo.loading" class="fas fa-spinner fa-spin"></i>
            </span>
          </button>
        </div>
      </div>
      <div
        class="d-flex justify-content-end"
        v-if="!loading && status !== 'read' && notifications.length"
      >
        <button
          class="btn btn-sm btn-light text-black mr-3 mb-1 font-weight-bold"
          v-if="countInfo.unread"
          @click="markAllAsRead(true)"
        >
          Mark all as read
        </button>
      </div>
      <ul ref="notificationsList" class="notifications-list">
        <alert v-if="loading" class="bg-secondary text-white m-2 rounded" />
        <li
          class="text-center p-3 h3 m-0"
          v-if="!loading && !notifications.length"
        >
          No notifications found.
        </li>
        <template v-if="!loading">
          <li
            v-for="notification in notifications"
            :key="notification.id"
            class="p-2 m-0"
          >
            <div
              class="notification-container border rounded py-2 px-1"
              :class="notification.read ? 'read-status' : ''"
            >
              <div
                class="
                  notification-header
                  d-flex
                  text-dark
                  justify-content-end
                  align-items-center
                  border-bottom
                  px-2
                  py-1
                "
              >
                <p
                  class="
                    notification-date
                    small
                    font-weight-bold
                    mb-0
                    pointer-cursor
                    mr-3
                  "
                  v-tooltip.left="formattedDate(notification.created_at)"
                >
                  {{ humanizedDate(notification.created_at) }}
                </p>

                <div class="dropdown">
                  <i
                    class="fas fa-ellipsis-v fa-lg text-dark p-2"
                    aria-hidden="true"
                    type="button"
                    :id="`dropdownMoreActions-${notification.id}`"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  ></i>
                  <div
                    class="dropdown-menu dropdown-menu-end"
                    :aria-labelledby="`dropdownMoreActions-${notification.id}`"
                  >
                    <a
                      class="dropdown-item"
                      :class="notification.marking && 'disabled'"
                      href="#"
                      @click.prevent="
                        !notification.marking &&
                          toggleMarkNotification(notification)
                      "
                      >Mark as {{ notification.read ? "unread" : "read" }}</a
                    >
                    <a
                      class="dropdown-item"
                      href="#"
                      :class="notification.deleting && 'disabled'"
                      @click.prevent="
                        !notification.deleting &&
                          deleteNotification(notification.id)
                      "
                      >Delete</a
                    >
                  </div>
                </div>
              </div>
              <div
                class="
                  notification-content
                  d-flex
                  align-items-center
                  pt-1
                  pointer-cursor
                "
                @click="navigateToNotification(notification)"
              >
                <div class="notification-icon border rounded mr-2 p-1">
                  <i
                    class="fas fa-2x text-dark"
                    :class="getNotificationIcon(notification.type)"
                  ></i>
                </div>
                <div class="message text-left pr-1">
                  {{ notification.message }}
                </div>
              </div>
            </div>
          </li>
          <mugen-scroll
            :handler="loadMore"
            :should-handle="!busy && !loading"
            scroll-container="notificationsList"
          >
            <alert
              v-if="busy"
              class="
                text-center
                p-2
                mb-0
                mobile-tablet-loadmore mobile-tablet-shadow-card
                radius-0
                text-white
                bg-secondary
              "
              ><i class="fas fa-spin fa-circle-notch mr-2"></i>Loading more
              notifications...</alert
            >
          </mugen-scroll>
        </template>
      </ul>
    </div>
  </li>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MugenScroll from "vue-mugen-scroll";
import * as dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export default {
  components: { MugenScroll },
  data() {
    return {
      displayNotifications: false,
      busy: false,
      status: "",
      limit: 100,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      notifications: (state) => state.users.notifications.pagination.data,
      nextCursor: (state) => state.users.notifications.pagination.nextCursor,
      loading: (state) => state.users.notifications.loading,
      countInfo: (state) => state.users.notifications.countInfo,
      companyName: (state) => (state.settings.companyProfile || {}).name || "",
    }),
    showBadge: function () {
      return this.countInfo.total || this.countInfo.loading;
    },
  },
  created() {
    this.getNotifications({ status: this.status, limit: this.limit });
    this.getNotificationsCount();
  },
  mounted() {
    setTimeout(() => {
      this.listenToServerEvents();
    }, 1000);
  },
  beforeDestroy() {
    if (this.user) {
      window.Echo.leaveChannel(`App.Models.User.${this.user.id}`);
    }
  },
  methods: {
    ...mapActions({
      getNotifications: "users/getNotifications",
      getNotificationsCount: "users/getNotificationsCount",
      changeNotificationReadStatus: "users/updateNotificationStatus",
      addNewNotification: "users/addNewNotification",
    }),
    showNotifications() {
      this.displayNotifications = !this.displayNotifications;
      setTimeout(() => {
        if (this.displayNotifications) {
          // Attach the click event listener when the dropdown is shown
          document.addEventListener("click", this.handleClickOutside);
        } else {
          // Remove the click event listener when the dropdown is hidden
          document.removeEventListener("click", this.handleClickOutside);
        }
      }, 0);
    },
    handleClickOutside(event) {
      // Check if the click is outside the dropdown
      if (
        this.$refs.notificationsListContainer &&
        !this.$refs.notificationsListContainer.contains(event.target)
      ) {
        this.displayNotifications = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    changeStatus(status) {
      this.status = status;
      this.getNotifications({ status: this.status, limit: this.limit });
    },
    toggleMarkNotification(notification) {
      notification.marking = true;
      this.$forceUpdate();
      this.changeNotificationReadStatus({
        id: notification.id,
        status: !notification.read,
      }).then((success) => {
        if (success) {
          notification.marking = false;
          notification.read = !notification.read;
          this.$forceUpdate();
        }
      });
    },
    markAllAsRead(status) {
      this.changeNotificationReadStatus({
        id: 0,
        status,
      }).then((success) => {
        if (success) {
          this.notifications.forEach((notification) => {
            notification.read = status;
          });
          this.$forceUpdate();
        }
      });
    },
    loadMore() {
      if (this.nextCursor) {
        this.busy = true;
        this.getNotifications({
          status: this.status,
          limit: this.limit,
          nextCursor: this.nextCursor,
          noLoading: true,
        }).then(() => {
          this.busy = false;
        });
      }
    },
    formattedDate(dateToFormat) {
      if (dateToFormat && dateToFormat != "") {
        return dayjs(dateToFormat).format("YYYY-MM-DD (hh:mm A)");
      } else {
        return "";
      }
    },
    humanizedDate(dateToFormat) {
      return dayjs(dateToFormat).fromNow();
    },
    getNotificationIcon(type) {
      switch (type) {
        case "task":
          return "fa-envelope-open-text";
        case "folder":
          return "fa-folder-open";
        case "document":
          return "fa-file-alt";
        case "reaction":
          return "fa-grin-stars";
        case "treatment":
          return "fa-calendar-plus";
        case "treatment-accept":
          return "fa-calendar-check";
        case "treatment-reject":
          return "fa-calendar-xmark";
        case "intake":
          return "fa-file-signature";
        case "record-share":
          return "fa-file-video";
        default:
          return "fa-bell";
      }
    },
    navigateToNotification(notification) {
      if (!notification.read) {
        this.toggleMarkNotification(notification);
      }
      this.$router.push({
        name: notification.link.name,
        query: notification.link.query,
        params: notification.link.params,
      });
      this.showNotifications();
    },
    listenToServerEvents() {
      window.Echo.private(`App.Models.User.${this.user.id}`).notification(
        (event) => {
          if (event.environment_name != this.companyName) return;
          if (event.notification) {
            this.addNewNotification({ id: event.id, ...event.notification });
          }
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-menu-end {
  border: 1px solid rgba(18, 38, 63, 0.1);
  position: absolute;
  right: 0;
  left: auto;
  background-color: #ffffff !important;
}
.badge-theme {
  background-color: var(--theme-color);
  color: var(--btn-text-color);
  font-size: 12px;
}
.notification-img {
  width: 24px;
  height: 24px;
}
.badged {
  border-radius: 16px;
}

.notifications-list-container {
  position: absolute;
  width: 400px;
  max-width: calc(100vw - 24px);
  top: 42px;
  right: -10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  background-color: white;
  border-radius: 4px;

  .notifications-list {
    display: block;
    padding: 0;
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: hidden;
    list-style: none;

    .dropdown:not(.show):hover > .dropdown-menu {
      display: none;
    }
  }

  .read-status {
    .notification-content {
      opacity: 0.7;
    }
  }

  .active-btn {
    font-weight: bold;
    border-bottom-color: #2c7be5;
    border-bottom-width: 2px;
  }
}

.notification-header {
  background-color: rgba(37, 37, 37, 0.4);
}

.notification-container {
  .notification-content {
    transition: all 0.2s ease-in-out;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    &:hover {
      background-color: var(--theme-color);
    }
  }
}

@media (max-width: 991px) {
  .badge-theme {
    position: absolute;
    top: -22px;
    right: 0%;
    transform: translateX(50%);
  }
  .notifications-list {
    top: 54px;
    right: -12px;
  }
  .badged {
    width: 52px !important;
    padding: 0 !important;
    border-radius: 50%;
  }
}
</style>
